<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <div class="head-card">
        <div class="head-card-top">
          <h4>{{ $t('Prebid partners') }}</h4>
          <feather-icon
            v-b-tooltip.hover.top="$t('Close')"
            icon="XIcon"
            size="18"
            class="toggle-close cursor-pointer"
            @click="$router.go(-1)"
          />
        </div>
        <hr>
      </div>
      <div
        v-if="!rows.length"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div
        v-else
        class="partners-table"
      >
        <!-- search input -->
        <div class="custom-search d-flex justify-content-between align-items-center mb-1">
          <div>
            <strong>{{ $t('dsppartners.total') }}: {{ rows.length }}</strong>
          </div>
          <div class="d-flex">
            <b-button-toolbar
              class="ml-1"
              justify
            >
              <b-button-group>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  :to="{ path: `/prebidpartners/create`}"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="16"
                  />
                  {{ $t('dsppartners.addpartner') }}
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </div>
        </div>
        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :total-rows="totalRecords"
          :pagination-options="{enabled: true}"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
        >
          <!-- Slot: Table Column -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span
              v-if="props.column.label ==='Status'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.status') }}
            </span>
            <span
              v-else-if="props.column.label ==='Name'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.name') }}
            </span>
            <span
              v-else-if="props.column.label ==='Notes'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.notes') }}
            </span>
            <span
              v-else-if="props.column.label ==='Action'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.action') }}
            </span>
            <span v-else>
              {{ props.column.label }}
            </span>
          </template>

          <!-- Slot: Table Row -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Status -->
            <span
              v-if="props.column.field === 'status'"
              class="text-nowrap"
            >
              <b-form-checkbox
                :checked="props.row.status === 'active' ? true : false"
                name="check-button"
                switch
                @change="changeStatus(props.row._id)"
              />
            </span>

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'name'"
              class="text-nowrap"
            >
              <span class="d-flex justify-content-between">
                <div>
                  <router-link :to="'/prebidpartners/'+ props.row._id + '/'"><span>{{ props.row.name }} </span></router-link>
                </div>
              </span>
            </span>

            <!-- Column: Api url -->
            <span
              v-if="props.column.field === 'notes'"
              class="text-nowrap"
            >
              {{ props.row.notes }}
            </span>

            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
            >
              <span>
                <b-dropdown
                  variant="link"
                  right
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    :to="'/prebidpartners/'+ props.row._id + '/edit/'"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>{{ $t('form.edit') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    variant="danger"
                    @click="deletePrebidPartner(props.row._id, props.row.name)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                    />
                    <span>{{ $t('form.delete') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  {{ $t('message.pagelength') }}
                </span>
                <b-form-select
                  v-model="serverParams.limit"
                  :options="['5','10','20','40']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
              </div>
              <div>
                <b-pagination
                  :value="serverParams.page"
                  :total-rows="props.total"
                  :per-page="serverParams.limit"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard, BSpinner, BPagination, BFormSelect, BDropdown, BDropdownItem, BFormCheckbox, BButton, BButtonToolbar, BButtonGroup, VBTooltip,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Breadcrumbs,
    BSpinner,
    BCard,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BButton,
    BButtonToolbar,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      breadcrumbItems: [],
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: 'Status',
          field: 'status',
          width: '3%',
          type: 'boolen',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Notes',
          field: 'notes',
          width: '15%',
          tdClass: 'text-left',
        },
        {
          label: 'Action',
          width: '5%',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      serverParams: {
        page: 1,
        limit: 20,
      },
      totalRecords: 0,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    filterServerParams() {
      const filtredParams = {}
      Object.keys(this.serverParams).forEach(p => {
        if (this.serverParams[p] !== '') {
          filtredParams[p] = this.serverParams[p]
        }
      })
      return filtredParams
    },
  },
  watch: {
    'serverParams.limit': {
      handler() {
        this.fethPrebidPartners()
      },
      deep: false,
    },
  },
  created() {
    this.fethPrebidPartners()
    this.setBreadcrumbs()
  },
  methods: {
    async fethPrebidPartners() {
      await useJwt.prebidPartners(this.serverParams)
        .then(response => {
          this.rows = response.data.prebidpartners
          this.totalRows = response.data.count
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors[0].detail,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
    onPageChange(params) {
      this.serverParams.page = params.currentPage
      this.$router.replace({
        name: 'dashboard-prebidpartners',
        query: this.filterServerParams,
      }).catch(() => {})
    },
    onPerPageChange(params) {
      this.serverParams.limit = params.currentPerPage
      this.serverParams.page = 1
      this.$router.replace({
        name: 'dashboard-prebidpartners',
        query: this.filterServerParams,
      }).catch(() => {})
    },
    changeStatus(id) {
      const prebidpartner = this.rows.find(p => p._id === id)
      prebidpartner.status = prebidpartner.status === 'inactive' ? 'active' : 'inactive'
      if (prebidpartner.status === 'active') {
        this.$swal({
          icon: 'success',
          title: 'Activation!',
          text: 'Successfully activated.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        useJwt.setPrebidPartner(id, { prebidpartner })
      } else {
        this.$swal({
          title: 'Confirmation',
          text: 'Will be inactive',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deactivation!',
              text: 'Successfully deactivated',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else if (result.dismiss === 'cancel') {
            prebidpartner.status = 'active'
            this.$swal({
              title: 'Cancelled',
              text: 'Status remain active',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          useJwt.setPrebidPartner(id, { prebidpartner })
        })
      }
    },
    deletePrebidPartner(id, name) {
      this.$swal({
        title: `Delete ${name}?`,
        text: 'Do you really want to delete this?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.deletePrebidPartner(id).then(deleted => {
            if (deleted.status === 204) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('swal.successfullydeleted'),
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.row = []
              this.fethPrebidPartners()
            }
          })
        }
      })
    },
    setBreadcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$i18n.t('Prebid partners'),
          active: true,
        },
      ]
    },
  },
}
</script>
<style>
.partners-table .dropdown-toggle {
  padding: inherit;
}

.partners-table td:first-child,
.partners-table td:last-child {
  text-align: center!important;
}
</style>
